/* @media (min-width: 1200px) {
    .home-page-container .container {
        max-width: none; 
    }
} */

.col-sm-12 {
    text-align: center;
}

.col-sm-12 h2 {
    position: fixed;
    top: 20%; /* Adjust based on desired placement */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    z-index: 10; /* Ensure it's above the background */
    width: 100%; /* Ensure it spans the width of the viewport */
    text-align: center;
}

.home-page-container, .home-page-container .container {
    width: 100%;
    max-width: 100%;
}

.home-page-container .container {
    clip: rect(0, auto, auto, 0);
    overflow: hidden;
    position: absolute;
    height: 100vh;
    left: 0;
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
    -webkit-mask-image: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 100%);
}

.home-page-container .form-container-home {
    background-color: #ffffff;
    /* border-radius: 20px; */
    /* padding: 20px; */
    /* width: 100%; */
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: -15px;
    margin-right: -15px;
}

.container_solid {
    background: white;

    /* position this container at hte top of its  */
    top: 0;
}

.title_wrapper {
    position: fixed;
    display: block;  
    margin: auto;
    width: 100%;
    /* center the text wrapper vertically */
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

.title_wrapper h1 {
    text-align: center;
    font-size: 64px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Fraunces', serif;
    font-weight: 900;
}

.container_solid .title_wrapper h1 {
    /* the text background */
    background: url('../images/Industrial Business Park-12.jpg');
    background-size: 100vw auto;
    background-position: center;
    
    /* clip the text is possible */
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    
    /* fallback text color */
    color: black;
}

.container_image {
    background-image: url('../images/Industrial Business Park-12.jpg');
    background-size:100vw auto;
    background-position: center;
    background-attachment: fixed;
    
    /* position the second container below the first container */
    top: 100vh;
}

.home-page-container {
    position: relative;
    height: 140vh;
    width: 100vw;
    overflow: auto;
    background-image: url('../images/Industrial Business Park-12.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    /* margin-left: 0px;
    margin-right: 0px; */
}

.container_image .title_wrapper h1 {
    color: white;
}
  
section {
    min-height: 100vh;
    padding: 2em;
    margin: auto;
    max-width: 800px;
}

section h2 {
    font-family: 'Fraunces', serif;
}

section p {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}