.contact-us-container {
    padding: 20px;
    width: 100%;
}

.form-container-contact-us {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.contact-container {
    position: relative;
    height: 100%;
    width: 100%;
}