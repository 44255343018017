body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.full-width {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.footer-container {
    padding: 15px 0;
    background: #333333;
    border-top: 0px solid dodgerblue;
    min-height: 186px;
    margin-top: auto;
    width: 100%;
    box-sizing: border-box;
}

div {
    display: block;
    unicode-bidi: isolate;
    margin: 0;
    padding: 0;
}
.footer-logo {
    width: 100%;
    font-size: 30px;
    font-weight: bold;
    margin: 0px;
    padding-top: 30px;
    color: #ffffff;
}

.ftr-logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ftr-logo p {
    color: #ffffff;
    font-size: 12px;
}

h1 {
    padding: 0;
}