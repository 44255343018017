.directory-tree-container {
    overflow-x: auto;
}

.directory-tree-list {
    list-style-type: none;
    padding-left: 0; /* No padding for the root */
}

.directory-tree-item, .directory-tree-item-name {
    cursor: pointer;
    display: inline-block;
}

.directory-tree-item-name {
    margin-left: 5px;
}

.directory-tree-list.child-directory, .directory-tree-container > .directory-tree-list > li > ul {
    padding-left: 20px; /* Adjust as needed */
}

/* Additional adjustment for nested directories to ensure consistent visual structure */
.directory-tree-list li ul {
    margin-top: 5px;
    border-left: 1px dashed #ccc;
    padding-left: 10px;
}

.directory-tree-list li {
    position: relative; /* Adjust if necessary for alignment */
}
