@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        padding: 10px;
    }
    .navbar-links {
        flex-direction: column;
        gap: 10px;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3EA0FF;
    padding: 15px 20px;
    width: 100%;
}

.navbar-title {
    margin: 0;
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
}

.navbar-links {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 20px;
}

.navbar-links li a {
    text-decoration: none;
    color: #333;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-sub {
    background-color: #ffffff;
    padding: 10px 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.navbar-buttons {
    display: flex;
    gap: 10px;
}

.navbar-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #333;
    text-decoration: none;
    border-radius: 20px;
    transition: background-color 0.3s ease;
    line-height: normal;
    height: 100%;
    border: 1px solid transparent;
}

.navbar-button:hover {
    background-color: #f2f2f2;
}

.navbar-sub-link {
    color: #333; /* Change the color as needed */
    text-decoration: none; /* Remove the underline */
    display: inline-flex; /* Make the link a flex container */
    align-items: center; /* Vertically center the text */
    line-height: 20px;
    padding: 10px 15px;
    margin-right: 10px;
    font-size: 18px;
}

.navbar-links li a.navbar-sub-link {
    color: #666;
    padding: 12px 30px;
}

.navbar-dropdown {
    position: relative;
    display: inline-block;
}

.navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.navbar-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    margin-right: 10px;
    margin: 0;
}

.navbar-dropdown-content a:hover {
    background-color: dodgerblue;
    color: white;
}

.navbar-dropdown:hover .navbar-dropdown-content {
    display: block;
}

.navbar-sub-link:hover {
    background-color: dodgerblue;
    color: white;
    padding: 10px 15px;
    margin-right: 10px;
}

.navbar-links li a.navbar-sub-link:hover {
    color: white;
}

