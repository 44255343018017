.password-reset-container {
    padding: 20px;
    width: 100%;
}

.form-container-password-reset {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.password-reset-inner-container {
    position: relative;
    height: 100%;
    width: 100%;
}