.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.footer-container {
    padding: 15px;
    background: #333333;
    border-top: 0px solid dodgerblue;
    min-height: 186px;
    margin-top: auto;
}