.dxeRadioButtonList td.dxe, .dxeCheckBoxList td.dxe {
    padding: 0px 5px 6px 0px;
}
.dxeEditArea.dxeDisabled, .dxeDisabled .dxeMemoEditArea {
    color: #000000 !important;
}

.divLayoutMaxWidth.fullBody, #panelContainer {
    padding: 20px 15px !important;
    background: #fff !important;
    margin: 20px 0px !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 5px #ccc !important;
    width: 100%;
    display: inline-block;
}