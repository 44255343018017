@keyframes drip {
    0% {
        transform: translateY(0) scale(0.5);
        opacity: 1;
    }
    30% {
        transform: translateY(5px) scale(1.2);
    }
    60% {
        transform: translateY(10px) scale(0.8);
    }
    100% {
        transform: translateY(15px) scale(0.5);
        opacity: 0;
    }
}

.admin-dashboard-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.table-responsive {
    overflow-x: auto;
}

.form-container-admin-home {
    background-color: #ffffff;
    border-radius: 20px; 
    padding: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: -15px;
    margin-right: -15px;
}

.border {
    border: var(--cui-border-width) var(--cui-border-style) var(--cui-border-color) !important;
}

.align-middle {
    vertical-align: middle !important;
}

.text-center {
    text-align: center !important;
}

.table .column-id,
.table .column-company-name,
.table .column-account-number,
.table .column-associated-users {
    text-align: left; /* Adjust as needed */
    vertical-align: middle;
}

.table .column-id { width: 10%; }
.table .column-company-name { width: 30%; }
.table .column-account-number { width: 20%; }
.table .column-associated-users { width: 40%; }

.table .column-role { width: 35%;}

.table input[type="text"] {
    width: 100%; /* Makes input fields take up the full column width */
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.table th {
    background-color: #f2f2f2;
    color: #333;
    padding: 10px;
}

.unassociated-users-container {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.unassociated-users-container h3 {
    margin-bottom: 15px;
}

.unassociated-users-container ul {
    list-style: none;
    padding: 0;
}

.unassociated-users-container li {
    margin-bottom: 10px;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.unassociated-users-container button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.unassociated-users-container button:hover {
    background-color: #45a049;
}

.admin-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: dodgerblue;
    color: #ffffff;
    text-decoration: none;
    border-radius: 20px;
    transition: background-color 0.3s ease;
    line-height: normal;
    height: 100%;
    border: 1px solid transparent;
}

.admin-button:hover {
    background-color: #5AB1FF;
}

.admin-button.red {
    background-color: red;
    padding: 3px 20px;
}

.admin-button.red:hover {
    background-color: darkred;
}

.admin-button.black {
    background-color: black;
}

.admin-button.black:hover {
    background-color: darkred;
}

.admin-button.light-green {
    background-color: #90EE90;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px
}

.admin-button.light-green:hover {
    background-color: #98FB98;
}

.admin-button.green {
    position: relative;
    overflow: visible;
    background-color: green;
}

.admin-button.green::before,
.admin-button.green::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%); /* Center vertically */
    width: 17px; /* Width of the metal bars */
    height: 20px; /* Height of the metal bars */
    background-color: #ccc; /* Color of the metal bars */
    border-radius: 2px; /* Slightly rounded corners for the metal bars */
}

.admin-button.green::before {
    left: -15px; /* Position the left bar outside the button */
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.admin-button.green::after {
    right: -15px; /* Position the right bar outside the button */
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.admin-button.green:hover {
    background-color: black;
}

.admin-button.black::after, .admin-button.black::before {
    content: "";
    position: absolute;
    bottom: -7px;
    width: 8px; /* Slightly larger for more pronounced droplets */
    height: 11px;
    background-color: #8A0303; /* Blood-like color */
    border-radius: 50%;
    opacity: 0; /* Start fully transparent */
    transform: translateY(0) scale(0);
    transition: all 0.5s ease-in-out;
}

.admin-button.black:hover::after, .admin-button.black:hover::before {
    animation: drip 1s ease-in-out infinite;
}

.admin-button.black:hover::after {
    left: 25%;
}

.admin-button.black:hover::before {
    left: 75%;
    animation-delay: 0.5s; /* Stagger the start of the animation */
}

.admin-button.black {
    position: relative;
    overflow: visible; /* Ensure the droplets are visible outside the button */
}

.admin-dashboard-panel {
    position: relative;
    background-color: #ffffff; /* Inherits from .form-container */
    border-radius: 20px; /* Inherits from .form-container */
    padding: 20px; /* Inherits from .form-container */
    padding-bottom: 5px;
    margin-top: 20px; /* Inherits from .form-container */
    margin-bottom: 20px; /* Inherits from .form-container */
    margin-left: -15px; 
    margin-right: -15px; 
    /* Add or override properties here */
}

.admin-dashboard-panel h2 {
    color: #ffffff;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    text-shadow: 1px 1px 2px #888;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 2px solid #4A90E2;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    /* background-color: #f0f0f0; */
    background-color: #3EA0FF;
    transition: all 0.3s ease;
}

.admin-dashboard-panel p {
    color: #ffffff;
    background-color: #333333;
    text-align: center;
    margin-top: -20px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.admin-generator-panel {
    position: relative;
    background-color: #ffffff; 
    border-radius: 20px; 
    padding: 20px; 
    margin-top: 20px; 
    margin-bottom: 20px; 
    margin-left: -15px; 
    margin-right: -15px; 
}

.admin-generator-panel h2 {
    color: #ffffff;
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    text-shadow: 1px 1px 2px #888;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 2px solid #4A90E2;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    /* background-color: #f0f0f0; */
    background-color: #3EA0FF;
    transition: all 0.3s ease;
    position: relative;
}

.admin-generator-panel p {
    color: #ffffff;
    background-color: #333333;
    text-align: center;
    margin-top: -20px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    position: relative;
}

.admin-generator-panel .table {
    /* Your base styles for tables within admin-generator-panel */
    width: 90%; /* Example: Full width */
    margin: 0 auto; /* Center the table */
    position: relative;
}

/* .admin-generator-panel .table .thead {
    width: 95%;
    max-width: 100%;
    min-width: 50%;
    margin: 0 auto;
}

.admin-generator-panel .table .thead .th {
    width: 95%;
    max-width: 100%;
    min-width: 50%;
    margin: 0 auto;
} */

.admin-generator-panel .table .column-id,
.admin-generator-panel .table .column-company-name,
.admin-generator-panel .table .column-account-number,
.admin-generator-panel .table .column-associated-users {
    /* Adjusted styles specific to tables within admin-generator-panel */
    text-align: left; /* Example: Keep text alignment */
    vertical-align: middle; /* Example: Keep vertical alignment */
}

.admin-generator-panel .table input[type="text"] {
    /* Specific styles for input within tables of admin-generator-panel */
    width: 400px; /* Example: Full width */
    padding: 4px; /* Example: Adjust padding */
    margin-right: -30px;
    margin-left: -30px;
    border: 1px solid #ccc; /* Example: Adjust border */
    border-radius: 4px; /* Example: Adjust border radius */
    transform: translateY(15%);
}

.admin-generator-panel .table select {
    margin-top: 5px;
    height: 34px;
    padding: 0 10px;
    margin-right: -20px;
    margin-left: -20px;
}

.admin-generator-panel .table th,
.admin-generator-panel .table td {
    width: auto;
    text-align: center;
}

/* Example: Specific adjustments for table rows within admin-generator-panel */
.admin-generator-panel .table tr:nth-child(even) {
    background-color: #f9f9f9; /* Zebra striping for rows */
}

.table-responsive h3 {
    color: #ffffff;
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    text-shadow: 1px 1px 2px #888;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 2px solid #4A90E2;
    border-radius: 10px;
    /* background-color: #f0f0f0; */
    background-color: #3EA0FF;
    transition: all 0.3s ease;
}

.unassociated-users-container h3 {
    color: #ffffff;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    text-shadow: 1px 1px 2px #888;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 2px solid #4A90E2;
    border-radius: 10px;
    /* background-color: #f0f0f0; */
    background-color: black;
    transition: all 0.3s ease;
}

.form-container-admin-documents h2 {
    color: #ffffff;
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    text-shadow: 1px 1px 2px #888;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 2px solid #4A90E2;
    border-radius: 10px;
    /* background-color: #f0f0f0; */
    background-color: #3EA0FF;
    transition: all 0.3s ease;
}

.upload-button {
    /* margin-top: -10px; */
    display: inline-block;
    padding: 5px 10px;
    background-color: dodgerblue;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    line-height: normal;
    height: 100%;
    border: 1px solid transparent;
}

.upload-button:hover {
    background-color: #5AB1FF;
}

.admin-button.red.delete {
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: -10px;
}