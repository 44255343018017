/* document.css is the document.js frontend css file */
@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.doc-tablebordered {
    width: 100%;
    border: 1px solid #9F9F9F !important;
    background-color: #FFFFFF !important;
    color: #000000;
}

.doc-table-folder {
    border-right: 1px solid #333333;
    border-bottom: none !important;
    border-collapse: collapse;
    border-left: none;
    padding: 2px;
}

.custom-table {
    width: 100%;
    border-top: 1px solid #CFCFCF;
    border-left: 1px solid #CFCFCF;
    border-collapse: collapse;
    /* empty-cells: show; */
}

table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    max-width: 100%;
    background-color: transparent;
    border-spacing: 0;
}

.custom-table th:first-child {
    border-left: 1px solid #102f56;
}

/* Align "Date" column text */
.custom-table td:nth-child(2) {
    text-align: center; /* Adjust this as needed */
}

/* Align "Size" column text */
.custom-table td:nth-child(3) {
    text-align: right; /* Adjust this as needed */
}

.custom-table tr th {
    background-color: dodgerblue !important;
    color: #ffffff;
}

.custom-table-table th {
    border-bottom: 3px Solid #b1b1b1;
    border-right: 1px solid #b1b1b1;
    border-top: 1px solid #b1b1b1;
    padding: 6px;
}

.custom-table th {
    font-size: 14px;
    border-bottom: 3px Solid #888888;
    border-right: 1px solid #888;
    border-top: 1px solid #888;
    background-color: #a5a5a5;
    padding: 4px 6px 6px;
}

.custom-table td {
    overflow: hidden;
    border-bottom: 1px Solid #CFCFCF;
    border-right: 1px solid #CFCFCF;
    border-top-width: 0;
    border-left-width: 0;
    padding: 3px 6px 4px;
}

.custom-table tbody td:empty {
    display: none;
}

.doc-table-folder-head {
    border: 1px solid #333333;
    border-collapse: collapse;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}

th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: left;
}

td {
    display: table-cell;
    vertical-align: inherit;
    unicode-bidi: isolate;
}

div {
    display: block;
    unicode-bidi: isolate;
}

form {
    display: block;
    margin-top: 0em;
    unicode-bidi: isolate;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}

.directory-tree-text-left {
    text-align: left;
}