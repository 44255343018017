.create-password-container {
    padding: 20px;
    width: 100%;
}

.form-container-create-password {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.create-password-inner-container {
    position: relative;
    height: 100%;
    width: 100%;
}