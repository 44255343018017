body {
    background-color: #f2f2f2;
}

.form-container {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: -200px;
    margin-right: -200px;
}

.create-account-container {
    padding: 20px;
    width: 100%;
}

.form-container-create-account {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.create-account-inner-container {
    position: relative;
    height: 100%;
    width: 100%;
}