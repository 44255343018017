.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

fieldset.helpText {
  font-size: 13px;
}

fieldset {
  padding: 0;
  margin: 0;
}

fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
}

.BodyContainer {
  background: none;
}

div {
  display: block;
  unicode-bidi: isolate;
}
.divLayoutMaxWidth.fullBody, #panelContainer {
  padding: 20px 15px !important;
  background: #fff !important;
  margin: 20px 0px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 5px #ccc !important;
  width: 100%;
  display: inline-block;
}